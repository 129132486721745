import {
  Box,
  Fade,
  Modal,
  Button,
  Backdrop,
  Typography,
  useMediaQuery
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { imageFallback, useDomain } from '../../utils';
import { usePropertyDetailsQuery } from '../../generated/graphql';
import useInfo from '../Dashboard/components/InfoCard';

function Home() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const { background_color } = useInfo();
  //   const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery('(max-width:480px)');

  const domainId = useDomain();
  const { data: propertyDetails } = usePropertyDetailsQuery({
    variables: {
      domain: domainId as string
    }
  });
  const imgUrl = propertyDetails?.getPropertyByDomain?.img;

  React.useEffect(() => {
    setTimeout(handleOpen, 500);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'primary.main',
        justifyContent: 'center',
        fontSize: theme => theme.spacing(2.5)
      }}
    >
      <Box sx={{ mb: 3, textAlign: 'center' }}>
        <Box
          height={40}
          src={imgUrl}
          alt="App Logo"
          component="img"
          onError={imageFallback}
        />
      </Box>

      <Modal
        open={open}
        disableAutoFocus
        closeAfterTransition
        disableEscapeKeyDown
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 1000 }}
      >
        <Fade in={open}>
          <Box
            sx={{
              p: 3,
              top: '50%',
              left: '50%',
              textAlign: 'center',
              position: 'absolute',
              bgcolor: 'background.paper',
              transform: 'translate(-50%, -50%)',
              width: isMobileScreen ? '90vw' : 400,
              borderRadius: theme => theme.spacing(1.25),
              backgroundColor: background_color
            }}
          >
            <Typography variant="h6" component="h2" color="text.tile">
              {domainId === 'dharanaretreat'
                ? 'Are you staying in our retreat?'
                : 'Are you staying in this hotel?'}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                onClick={() => navigate('/verify-user')}
              >
                Yes
              </Button>
              <Button
                sx={{ ml: 1.5 }}
                variant="outlined"
                onClick={() => navigate('/not-verified')}
              >
                No
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Home;
